<template>
  <prime-tab-menu
    :model="model"
    :active-index="modelActiveIndex"
    :class="$attrs.class"
    :pt="{
      root: { class: ['de-tabs', `de-tabs-${size}`, `de-tabs-${variant}`] },
      menu: { class: ['de-tabs-menu', menuClass] },
      menuitem: (menuItemProps) => ({
        class: [
          'de-tabs-menu-item tw-leading-400',
          tabClass(menuItemProps.context.item),
          menuItemProps.context.item.class,
        ],
      }),
      inkbar: { class: ['de-tabs-ink-bar'] },
      action: ({ context, state }) => ({
        class: {
          'is-active': state.d_activeIndex === context.index,
        },
      }),
    }"
    @tab-change="onTabChange"
  >
    <template #item="{ item, active }">
      <component
        :is="getTabItemComponent(item)"
        :href="item.url || undefined"
        class="tw-leading-400"
      >
        <button class="de-menu-item-action" :class="[{ 'is-active': active }, actionClass(item)]">
          {{ item.label }}
        </button>
      </component>
    </template>
  </prime-tab-menu>
</template>

<script setup lang="ts">
import type { TabMenuChangeEvent } from 'primevue/tabmenu';
import { resolveComponent } from 'vue';
import type { TabItem } from '~/shared/components/lib/tabs/tab.interface';
import { TabSizeOptions, TabVariantOptions } from '~/shared/components/lib/tabs/tab.entity';

const props = withDefaults(
  defineProps<{
    model: TabItem[];
    menuClass?: string;
    variant?: TabVariantOptions;
    size?: TabSizeOptions;
    tabClass?: (item: TabItem) => string | undefined;
    actionClass?: (item: TabItem) => string | undefined;
  }>(),
  {
    variant: TabVariantOptions.default,
    size: TabSizeOptions.normal,
    tabClass: (_item: TabItem) => undefined,
    actionClass: (_item: TabItem) => undefined,
  },
);

const emit = defineEmits(['tab-change']);

const modelValue = defineModel<string>('value');
const modelActiveIndex = defineModel<number>();

if (modelValue.value) {
  const foundIndex = props.model?.findIndex((item) => item.id === modelValue.value);
  modelActiveIndex.value = foundIndex > 0 ? foundIndex : 0;
}

const onTabChange = (event: TabMenuChangeEvent): void => {
  modelActiveIndex.value = event.index;
  modelValue.value = props.model[event.index].id;

  emit('tab-change', event.index, modelValue.value);
};

function getTabItemComponent(item: TabItem) {
  if (item.url) return resolveComponent('NuxtLinkLocale');

  return 'div';
}
</script>
