export enum TabSizeOptions {
  normal = 'normal',
  medium = 'md',
}

export enum TabVariantOptions {
  default = 'default',
  pills = 'pills',
  mobileDefautlTabletPills = 'mobileDefaultTabletPills',
}
